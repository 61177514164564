import { OrderFulfillment } from "../models/OrderFulfillment";
import { getDomainOverride } from "../utilities";
import { useAction, useOidcStore, useStoreActions } from "@points/common";
import { computed, ref, toRefs, watch } from "vue";
import { defineStore } from "pinia";
import { getWholesaleCompany } from "../api/company";
import { getCurrentUser } from "../api/user";
export const useMainStore = defineStore("main", function setup() {
    const oidcStore = useOidcStore();
    const { isAuthenticated } = toRefs(oidcStore);
    /**
     * Private ref to wholesalecompany which must be initialized before using displaying the app
     */
    const _wholesaleCompany = ref(undefined);
    /**
      * Wholesale company which sells via the current domain or tenant id, [todo: remove tenant id to avoid info leaks]
      * unavailable until app is successfully authorized or identified
      * via domain-name
      */
    const wholesaleCompany = computed(() => {
        if (!_wholesaleCompany.value)
            throw new Error("Attempted to use wholesaleCompany before app was initialized");
        return _wholesaleCompany.value;
    });
    /**
     * Wholesale user information for the currently authenticated user
     */
    const wholesaleUser = ref(undefined);
    const loadWholesaleCompany = useAction("abort", async (_signal) => {
        await loadWholesaleUser.createAwaitable();
        // TODO: remove companyId from this call to avoid rendering router-view for invalid domains
        const company = await getWholesaleCompany(getDomainOverride());
        // Parse company theme object
        if (typeof company?.theme === "string" && company.theme.trim().length > 0)
            company.theme = JSON.parse(company.theme);
        _wholesaleCompany.value = company;
    });
    const loadWholesaleUser = useAction("abort", async (_signal) => {
        // Load wholesale user info for identity server user if user is authenticated
        wholesaleUser.value = isAuthenticated.value
            ? await getCurrentUser()
            : undefined;
    });
    watch(isAuthenticated, () => loadWholesaleUser.execute());
    return {
        ...useStoreActions({
            loadWholesaleCompany,
            loadWholesaleUser
        }),
        wholesaleCompany,
        wholesaleUser,
        isTractionWholesaleCompany: computed(() => wholesaleCompany.value.orderFulfillment === OrderFulfillment.Traction)
    };
});
